import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { ArtgetextComponent } from './artgetextehaltung/artgetextehaltung.component';
import { ImpressumComponent } from './impressum/impressum.component';

const routes: Routes =[
    { path: '',                 component: ArtgetextComponent },
    { path: 'impressum',        component: ImpressumComponent },
    { path: '*', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes,{
      useHash: true
    })
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
