import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-artgetext',
    templateUrl: './artgetextehaltung.component.html',
    styleUrls: ['./artgetextehaltung.component.scss']
})

export class ArtgetextComponent implements OnInit {
  focus: any;
  focus1: any;

  constructor() { }

  ngOnInit() {}

}
